import React, { useContext, useEffect, useState } from 'react';

import classnames from 'classnames';

import 'css/components/www/LandingV3/_LandingNav.scss';

import { LocationContext } from 'common/containers/RouterContainer';
import NavLogo from 'common/NavLogo';
import NavCTA from 'common/www/LandingV3/LandingNav/LandingNavCTA';
import NavLinks from 'common/www/LandingV3/LandingNav/LandingNavLinks';

import LandingNavMobile from './LandingNavMobile';

export type NavItem = {
  copy: string;
  link?: string;
  heading?: boolean;
  external?: boolean;
};

export type Nav = Record<string, NavItem[] | string>;

const routesForWhiteNav = [
  '/',
  '/case-studies/ahrefs',
  '/case-studies/agencyanalytics',
  '/case-studies/aryeo',
  '/case-studies/axios',
  '/case-studies/circleci',
  '/case-studies/clickup',
  '/case-studies/akiflow',
  '/case-studies/document360',
  '/case-studies/figured',
  '/case-studies/givebutter',
  '/case-studies/hive',
  '/case-studies/janetechnologies',
  '/case-studies/knak',
  '/case-studies/missive',
  '/case-studies/mercury',
  '/case-studies/pallyy',
  '/case-studies/readme',
  '/case-studies/stickermule',
  '/case-studies/strapi',
  '/case-studies/outlier',
  '/features/analyze-feedback',
  '/features/autopilot',
  '/features/collect-feedback',
  '/features/product-changelog',
  '/features/product-roadmap',
  '/powered-by-canny',
  '/use-cases/bug-tracking-tool',
  '/use-cases/enterprise-feedback-management',
  '/use-cases/feature-request-management',
  '/use-cases/idea-management',
  '/use-cases/internal-feedback',
  '/use-cases/product-management',
  '/use-cases/public-roadmap',
  '/use-cases/sales-documentation',
];

const routesWithSkipLink = ['/', '/features/autopilot'];

const desktopNavItems: Nav = {
  product: [
    { copy: 'Features', link: '/features', heading: true },
    { copy: 'AI Feedback Management', link: '/features/autopilot' },
    { copy: 'Collect Feedback', link: '/features/collect-feedback' },
    { copy: 'Analyze Feedback', link: '/features/analyze-feedback' },
    { copy: 'Plan Roadmap', link: '/features/product-roadmap' },
    { copy: 'Share Updates', link: '/features/product-changelog' },
    { copy: 'Use Cases', link: '/use-cases', heading: true },
    { copy: 'Feature Request Management', link: '/use-cases/feature-request-management' },
    { copy: 'Idea Management', link: '/use-cases/idea-management' },
    { copy: 'Internal Feedback', link: '/use-cases/internal-feedback' },
    { copy: 'Product Management', link: '/use-cases/product-management' },
    { copy: 'Sales Documentation', link: '/use-cases/sales-documentation' },
    { copy: 'Public Roadmap', link: '/use-cases/public-roadmap' },
    { copy: 'Bug Tracking', link: '/use-cases/bug-tracking-tool' },
    { copy: 'Enterprise Feedback', link: '/use-cases/enterprise-feedback-management' },
    { copy: 'Customer Feedback', link: '/use-cases/customer-feedback' },
    { copy: 'Integrations', link: '/integrations', heading: true },
  ],
  resources: [
    { copy: 'Learn', heading: true },
    { copy: 'Canny Blog', link: 'https://canny.io/blog/', external: true },
    { copy: 'Product Chats Podcast', link: 'https://productchats.canny.io/', external: true },
    {
      copy: 'Free Prioritization Spreadsheet',
      link: 'https://go.canny.io/free-prioritization-template',
      external: true,
    },
    {
      copy: 'Guide to Managing User Feedback',
      link: 'https://go.canny.io/being-canny-guide',
      external: true,
    },
    { copy: 'Documentation', heading: true },
    { copy: 'Install Canny', link: 'https://developers.canny.io/install', external: true },
    { copy: 'API Docs', link: 'https://developers.canny.io/api-reference', external: true },
    { copy: 'Other Resources', heading: true },
    { copy: 'Help Center', link: 'https://help.canny.io/en/', external: true },
    { copy: 'Feedback', link: 'https://feedback.canny.io/', external: true },
    { copy: 'Security', link: '/security' },
  ],
  customers: '/case-studies',
  pricing: '/pricing',
};

const mobileNavItems: NavItem[] = [
  { copy: 'Features', link: '/features' },
  { copy: 'Use Cases', link: '/use-cases' },
  { copy: 'Pricing', link: '/pricing' },
  { copy: 'Blog', link: 'https://canny.io/blog/', external: true },
  { copy: 'Case Studies', link: '/case-studies' },
  { copy: 'Request a demo', link: '/request-demo' },
];

const LandingNav = () => {
  const [stickNav, setStickNav] = useState(false);

  const location = useContext(LocationContext);

  const isLanding = location.pathname === '/';

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  });

  const onScroll = () => {
    const scrollTop = document.scrollingElement?.scrollTop ?? 0;

    if (scrollTop > 500) {
      setStickNav(true);
    } else {
      setStickNav(false);
    }
  };

  const affix = isLanding && stickNav;
  const whiteBackground = !!routesForWhiteNav.includes(location.pathname);
  const enableSkipLink = !!routesWithSkipLink.includes(location.pathname);

  return (
    <header
      className={classnames('LandingNav', {
        'LandingNav--fixed': isLanding,
        'LandingNav--affix': affix,
        'LandingNav--white': whiteBackground,
      })}>
      {enableSkipLink && (
        <a href="#main" className="LandingNav__skip-link">
          Skip Navigation
        </a>
      )}
      <div className="LandingNav__wrapper">
        <div className="LandingNav__logo">
          <NavLogo />
        </div>
        {/* Dropdown Nav */}
        <NavLinks nav={desktopNavItems} />
        <div className="LandingNav__inner-wrapper">
          <NavCTA showSignIn={isLanding} />
          {/* Bar Nav */}
          <LandingNavMobile mobileNavItems={mobileNavItems} />
        </div>
      </div>
    </header>
  );
};

export default LandingNav;
